import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';

const BACKEND_URL = environment.apiUrl + '/user';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isAuthenticated = false;
    private token: string;
    private tokenTimer: any;
    private userId: string;
    private userName: string;
    private name: string;
    private level: string;
    private error = '';
    private authStatusListner = new Subject<boolean>();
    private checkNewUser: any;
    constructor(private http: HttpClient, private router: Router) { }

    getToken() {
        return this.token;
    }

    getIsAuth() {
        return this.isAuthenticated;
    }

    getUserId() {
        return this.userId;
    }

    getUserName() {
        return this.userName;
    }

    getUserLevel() {
        return this.level;
    }

    getName() {
        return this.name;
    }
    getNewUser(){        
       return this.checkNewUser;
    }

    // login(username: string, password: string) {
    //     const authData = { username, password };
    //     return this.http.post<{ message: string, status: Number }>(BACKEND_URL + "/login", authData);
    // }

    login(userName: string, password: string) {
        const authData = { userName, password };
        this.http.post<{ message: string, token: string, expiresIn: number, id: string, userName: string, name: string, level: any, newUser:any }>(
            BACKEND_URL + "/login", authData)
            .subscribe(response => {
                
                const token = response.token;
                const message = response.message;
                this.token = token;
                if (token) {
                    this.error = '';
                    const expiresInDuration = response.expiresIn;
                    this.setAuthTimer(expiresInDuration);
                    this.isAuthenticated = true;
                    this.checkNewUser = response?.newUser
                   
                    
                    this.userId = response.id;
                    this.userName = response.userName;
                    this.name = response.name;
                    this.level = response.level;
                    this.authStatusListner.next(true);
                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
                    //console.log(expirationDate);
                   
                    this.saveAuthData(token, expirationDate, this.userId, this.userName, this.name, this.level, this.checkNewUser);
                    this.router.navigate(['/dashboard']);
                }
                else {
                    this.error = message;
                    this.authStatusListner.next(false);
                }
            }, error => {
                this.error = error.error.message;
                this.authStatusListner.next(false);
            });
    }

    getError() {
        return this.error;
    }

    getAuthStatusListner() {
        return this.authStatusListner.asObservable();
    }

    autoAuthUser() {
        const authInformation = this.getAuthData();
        console.log(authInformation,'authInformationauthInformation');
        
        if (!authInformation) {
            return;
        }
        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
        if (expiresIn > 0) {
            this.token = authInformation.token;
            this.isAuthenticated = true;
            this.userId = authInformation.userId;
            this.userName = authInformation.userName;
            this.name = authInformation.name;
            this.level = authInformation.level;
            this.setAuthTimer(expiresIn / 1000);
            this.authStatusListner.next(true);
        }
    }

    logout() {
        this.http.post(BACKEND_URL + "/logout", {}).subscribe(data => {
            //console.log(data);
            this.dlogout();
        }, error => {
            console.log(error);
        });
    }

    dlogout() {
        this.token = null;
        this.isAuthenticated = false;
        this.level = null;
        this.authStatusListner.next(false);
        clearInterval(this.tokenTimer);
        this.clearAuthData();
        this.userId = null;
        this.userName = null;
        this.name = null;
        this.router.navigate(['/auth/login']);
    }

    private setAuthTimer(duration: number) {
        //console.log("Setting Timer " + duration);
        this.tokenTimer = setTimeout(() => {
            this.logout();
        }, duration * 1000);
    }

    private saveAuthData(token: string, expirationDate: Date, userId: string, userName: string, name: string, level: string, newuser:any) {
        localStorage.setItem('tokenA', token);
        localStorage.setItem('expirationA', expirationDate.toISOString());
        localStorage.setItem('userIdA', userId);
        localStorage.setItem('userNameA', userName);
        localStorage.setItem('nameA', name);
        localStorage.setItem('levelA', level);
        localStorage.setItem('AjA', newuser);
    }

    private clearAuthData() {
        localStorage.removeItem('tokenA');
        localStorage.removeItem('expirationA');
        localStorage.removeItem('userIdA');
        localStorage.removeItem('userNameA');
        localStorage.removeItem('nameA');
        localStorage.removeItem('levelA');
        localStorage.removeItem('AjA');
    }

    getAuthData() {
        const token = localStorage.getItem("tokenA");
        const expirationDate = localStorage.getItem("expirationA");
        const userId = localStorage.getItem("userIdA");
        const userName = localStorage.getItem("userNameA");
        const name = localStorage.getItem("nameA");
        const level = localStorage.getItem("levelA");
        const AjA = localStorage.getItem("AjA");
        if (!token || !expirationDate) {
            return;
        }
        return {
            token: token,
            expirationDate: new Date(expirationDate),
            userId: userId,
            userName: userName,
            name: name,
            level: level,
            newUser: ''+AjA
        }
    }
}